<template>
  <v-autocomplete
    v-model="localAddress"
    item-text="street_1"
    item-value="id"
    append-icon="fal fa-times"
    data-cy="company-address-autocomplete"
    :attach="attach"
    :search-input.sync="search"
    :items="addresses"
    :loading="$apollo.loading"
    :filter="nameFilter"
    @click:append="search = ''"
  >
    <template v-slot:message="{ message, key }">
      <span :key="key" v-html="message"/>
    </template>
    <template #label>
      Select a company address
    </template>
    <template #item="{ item }">
      <v-list-tile-content>
        <v-list-tile-title> {{ getTitle(item) }} </v-list-tile-title>
        <v-list-tile-sub-title> {{ getSubtitle(item) }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
    <template
      v-slot:no-data
    >
      <v-list-tile v-if="$apollo.loading">
        <v-list-tile-title>
          Loading your addresses!
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else>
        <v-list-tile-title>
          No addresses found.
        </v-list-tile-title>
      </v-list-tile>
    </template>
  </v-autocomplete>
</template>

<script>
import commonAutofillFilters from '../mixins/commonAutofillFilters'
import { GET_ADDRESSES__LIST } from '@/api/graphql/Constants/Addresses'
export default {
  name: 'CompanyAddresses',
  mixins: [commonAutofillFilters],
  props: {
    address: {
      type: String,
      required: true
    },
    attach: {
      required: false,
      default: true
    }
  },
  computed: {
    localAddress: {
      get () {
        return Number(this.address)
      },
      set (value) {
        this.$emit('updated', { value: `${value}` })
      }
    }
  },
  watch: {
    search (newValue, oldValue) {
      newValue = newValue === null ? '' : newValue
      oldValue = oldValue === null ? '' : oldValue
      if (newValue.length < oldValue.length) {
        this.searched = false
      }
      this.search = newValue
      if (newValue.length > 2 && (!this.searched || this.addresses.length === 0)) {
        if (newValue === 'ALL') {
          this.search = ''
        }
        this.searched = true
      }
    }
  },
  data () {
    return {
      search: '',
      searched: false,
      addresses: [],
      skip: true
    }
  },
  apollo: {
    addresses: {
      query: GET_ADDRESSES__LIST,
      variables () {
        return {
          input: {
            filters:
              [{
                key: 'company__isnull',
                value: false
              }]
          }
        }
      },
      update: data => data.address_addresses
    }
  },
  methods: {
    getSubtitle (a) {
      return a.building_name
    },

    getTitle (a) {
      return `${a.street_1} ${a.city} ${a.state}`
    }
  }
}
</script>
