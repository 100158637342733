<template>
  <settings-template :config="config" :search="$attrs.search">
    <!-- Company Info -->
    <template #slot0>
      <v-card>
        <v-card-title class="info">
          <h3 class="white--text">Company Info</h3>
        </v-card-title>
        <v-progress-linear
          class="mt-0"
          v-if="$apollo.queries.company.loading"
          indeterminate
          color="info"
        />
        <v-expand-transition>
          <v-card-text v-if="!$apollo.queries.company.loading">
            <v-container grid-list-xl>
              <v-layout row wrap justify-start align-center>
                <v-flex shrink>
                  <v-text-field
                    label="Company Name"
                    solo
                    v-model="company.name"
                    hint="Company Name"
                    persistent-hint
                    readonly
                  />
                </v-flex>
                <v-flex shrink>
                  <v-text-field
                    label="Company Phone"
                    solo
                    v-model="company.phone"
                    hint="Company Phone"
                    persistent-hint
                    readonly
                  />
                </v-flex>
                <v-flex shrink>
                  <v-text-field
                    label="Company Short Name"
                    solo
                    v-model="company.short_name"
                    hint="Company Short Name"
                    persistent-hint
                    readonly
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </template>

    <!-- Company Defaults -->
    <template #slot1>
      <v-card data-cy="company-defaults-card">
        <v-card-title class="green">
          <h3 class="white--text">Company Defaults</h3>
        </v-card-title>
        <v-progress-linear
          class="mt-0"
          color="info"
          indeterminate
          v-if="!defaultsAreLoaded"
        />
        <v-expand-transition>
          <v-card-text v-if="defaultsAreLoaded">
            <v-container grid-list-xl>
              <v-layout row wrap justify-start align-center>
                <!-- Default New Client Terms -->
                <v-flex shrink>
                  <v-select
                    v-model="defaultTerms"
                    label="Default Terms"
                    hint="For new C/V's"
                    item-text="name"
                    item-value="id"
                    outline
                    persistent-hint
                    data-cy="default-company-terms"
                    :loading="$apollo.queries.terms.loading"
                    :items="terms"
                    @change="update('default_new_client_terms', $event)"
                  >
                    <template #prepend>
                      <v-icon color="purple"> fas fa-money-check-alt </v-icon>
                    </template>
                  </v-select>
                </v-flex>
                <!-- Default Ship From -->
                <v-flex shrink>
                  <v-select
                    v-model="defaultShipFrom"
                    label="Default Ship From Address"
                    item-text="street_1"
                    item-value="id"
                    clearable
                    outline
                    attach
                    data-cy="default-company-ship-from"
                    :items="addresses"
                    @change="update('default_ship_from_address', $event)"
                  >
                    <template #prepend>
                      <v-icon> fas fa-map-marked-alt </v-icon>
                    </template>
                  </v-select>
                </v-flex>
                <!-- Default Ship To -->
                <v-flex shrink>
                  <v-select
                    v-model="defaultShipTo"
                    label="Default Ship To Address"
                    item-text="street_1"
                    item-value="id"
                    outline
                    clearable
                    attach
                    data-cy="default-company-ship-to"
                    :items="addresses"
                    @change="update('default_ship_to_address', $event)"
                  >
                    <template #prepend>
                      <v-icon> fas fa-map-marked-alt </v-icon>
                    </template>
                  </v-select>
                </v-flex>
                <!-- Default Bill To -->
                <v-flex shrink>
                  <v-select
                    v-model="defaultBillTo"
                    label="Default Bill To Address"
                    item-text="street_1"
                    item-value="id"
                    outline
                    clearable
                    data-cy="default-company-bill-to"
                    :items="addresses"
                    @change="update('default_billing_address', $event)"
                  >
                    <template #prepend>
                      <v-icon> fas fa-map-marked-alt </v-icon>
                    </template>
                  </v-select>
                </v-flex>
                <!-- Default Return Address -->
                <v-flex shrink>
                  <v-select
                    v-model="defaultReturn"
                    label="Default Return Address"
                    item-text="street_1"
                    item-value="id"
                    clearable
                    outline
                    data-cy="default-company-return-to"
                    :items="addresses"
                    @change="update('default_return_to_address', $event)"
                  >
                    <template #prepend>
                      <v-icon> fas fa-map-marked-alt </v-icon>
                    </template>
                  </v-select>
                </v-flex>
                <!-- Default Carrier Account -->
                <v-flex shrink>
                  <v-select
                    v-model="defaultCarrierAccount"
                    label="Default Shipping Account"
                    item-text="title"
                    item-value="id"
                    clearable
                    outline
                    data-cy="default-company-carrier-account"
                    :items="carrierAccounts"
                    @change="update('default_shipping_account', $event)"
                  >
                    <template #prepend>
                      <v-icon color="brown"> fas fa-truck </v-icon>
                    </template>
                  </v-select>
                </v-flex>
                <!-- Default Carrier Service -->
                <v-flex shrink>
                  <v-select
                    v-model="defaultShipService"
                    label="Default Shipping Service"
                    item-text="title"
                    item-value="id"
                    clearable
                    outline
                    data-cy="default-company-carrier-service"
                    :items="carrierServices"
                    @change="update('default_shipping_service', $event)"
                  >
                    <template #prepend>
                      <v-icon color="brown"> fas fa-shipping-timed </v-icon>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </template>

    <!-- Allowed Terms -->
    <template #slot2>
      <v-card>
        <v-card-title class="purple">
          <h3 class="white--text">Allowed Terms</h3>
        </v-card-title>
        <v-card-text>
          <terms/>
        </v-card-text>
      </v-card>
    </template>
    <!-- Inventory Locations -->
    <template #slot3>
      <v-card>
        <v-card-title class="blue-grey darken-3">
          <h3 class="white--text">Inventory Locations</h3>
        </v-card-title>
        <v-card-text>
          <location-management />
        </v-card-text>
      </v-card>
    </template>

    <!-- Site Management -->
    <template #slot4>
      <v-card>
        <v-card-title class="blue-grey darken-3">
          <h3 class="white--text">Site Management</h3>
        </v-card-title>
        <v-card-text>
          <site-management />
        </v-card-text>
      </v-card>
    </template>

    <!-- Company Addresses -->
    <template #slot5>
      <v-card>
        <v-card-title class="brown">
          <h3 class="white--text">Our Addresses</h3>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="addressHeaders"
                :items="addresses"
                item-key="easypost_id"
                :rows-per-page-items="[5, 10, 15]"
                :loading="$apollo.queries.addresses.loading"
              >
                <template v-slot:no-data>
                  <v-alert
                    color="warning"
                    v-if="!$apollo.queries.addresses.loading"
                    :value="true"
                  >
                    No addresses? Add one!
                  </v-alert>
                  <v-alert color="info" v-else icon="sync" :value="true">
                    Loading your addresses, hold tight!
                  </v-alert>
                </template>
                <template v-slot:items="{ item }">
                  <td>{{ item.building_name }}</td>
                  <td>{{ item.address }}</td>
                  <td>
                    <v-layout justify-center fill-height>
                      <v-icon small v-if="item.easypost_id" color="primary"
                        >fas fa-check-circle</v-icon
                      >
                      <v-icon v-else color="red" small
                        >fal fa-times-circle</v-icon
                      >
                    </v-layout>
                  </td>
                  <td>
                    <v-btn
                      color="error"
                      icon
                      small
                      flat
                      @click="removeAddress(props.item.id)"
                    >
                      <v-icon small>fal fa-trash</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-layout row justify-start align-end>
                <v-flex shrink>
                  <t-alert :message="addressMessage" :type="addressType" />
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex shrink>
                  <v-dialog v-model="dialog" lazy>
                    <template #activator="{ on }">
                      <v-btn
                        color="info"
                        v-on="on"
                        data-cy="add-company-address-button"
                      >
                        Add
                      </v-btn>
                    </template>
                    <add-address
                      v-if="dialog"
                      :owner="-1"
                      :event-only="false"
                      @close="dialog = false"
                      @success="$apollo.queries.addresses.refetch()"
                    />
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </template>
  </settings-template>
</template>

<script>
import settingsTemplate from '@/views/settings/settingsTemplate'
import { COMPANY_INFO, UPDATE_COMPANY } from '@/api/graphql/Constants/Company'
import { GET_ADDRESSES__GRID } from '@/api/graphql/Constants/Addresses'
import termsSettings from '@/components/settings/termsSettings'
import { TERMS } from '@/api/graphql/Constants/TERMS'
import tAlert from '@/components/notifications/tAlert'
import { GetFullAddressLine } from '@/lib/gqlDataGetters'
import {
  GET_CARRIER_ACCOUNTS__AUTOFILL,
  GET_SHIPPING_SERVICE_LEVELS
} from '@/api/graphql/Constants/Shipments'
import LocationsManagement from '@/components/settings/LocationsManagement'
import SiteManagement from '@/components/settings/SiteManagement'
import newAddress from '@/components/templates/newAddress'

export default {
  name: 'companySettings',
  components: {
    'settings-template': settingsTemplate,
    terms: termsSettings,
    'add-address': newAddress,
    't-alert': tAlert,
    'location-management': LocationsManagement,
    'site-management': SiteManagement
  },
  data () {
    return {
      message: '',
      type: 'info',
      addressMessage: '',
      addressType: 'info',

      config: {
        icons: {
          icon0: {
            icon: 'far fa-building',
            color: 'info'
          },
          icon1: {
            icon: 'far fa-sliders-v-square',
            color: 'green'
          },
          icon2: {
            icon: 'far fa-money-check-alt',
            color: 'purple'
          },
          icon3: {
            icon: 'far fa-warehouse-alt',
            color: 'blue-grey darken-3'
          },
          icon4: {
            icon: 'far fa-map-marker-alt',
            color: 'blue-grey darken-2'
          },
          icon5: {
            icon: 'far fa-map-marked-alt',
            color: 'brown'
          }
        },
        slot0: 'Company Info',
        slot1: 'Company Defaults',
        slot2: 'Allowed Terms',
        slot3: 'Inventory Locations',
        slot4: 'Site Management',
        slot5: 'Company Addresses'
      },
      company: {},

      terms: [],
      carrierServices: [],
      carrierAccounts: [],

      defaultTerms: 0,
      defaultShipTo: 0,
      defaultBillTo: 0,
      defaultShipFrom: 0,
      defaultReturn: 0,
      defaultCarrierAccount: 0,
      defaultShipService: 0,

      defaultsAreLoaded: false,

      // company address
      addressHeaders: [
        { text: 'Name', value: 'building_name' },
        { text: 'Address', value: 'address' },
        { text: 'Verified', value: 'easypost_id' },
        { text: 'Actions', value: '', sortable: false }
      ],
      addresses: [],

      dialog: false
    }
  },
  apollo: {
    company: {
      query: COMPANY_INFO,
      update (data) {
        const company = data.company
        this.defaultReturn = company.default_return_to_address?.id ?? 0
        this.defaultShipTo = company.default_ship_to_address?.id ?? 0
        this.defaultShipFrom = company.default_ship_from_address?.id ?? 0
        this.defaultBillTo = company.default_billing_address?.id ?? 0
        this.defaultTerms = company.default_new_client_terms?.id ?? 0
        this.defaultCarrierAccount = company.default_shipping_account?.id ?? 0
        this.defaultShipService = company.default_shipping_service?.id ?? 0
        this.defaultsAreLoaded = true
        return data.company
      }
    },
    carrierAccounts: {
      query: GET_CARRIER_ACCOUNTS__AUTOFILL,
      variables: { filters: [{ key: 'client__isnull', value: true }] },
      update: data => data.shipping_accounts
    },
    carrierServices: {
      query: GET_SHIPPING_SERVICE_LEVELS,
      update: data =>
        data.shipping_service_levels.map(s => {
          s.title = `${s.shipper.name} ${s.service}`
          return s
        })
    },
    addresses: {
      query: GET_ADDRESSES__GRID,
      variables: {
        input: { filters: [{ key: 'company__isnull', value: false }] }
      },
      update (data) {
        return data.address_addresses.map(a => {
          a.address = GetFullAddressLine(a)
          return a
        })
      }
    },
    terms: {
      query: TERMS
    }
  },
  methods: {
    async removeAddress (id) {
      try {
        await this.DELETE_ADDRESS(id)
        const index = this.addresses.findIndex(item => item.id === id)
        this.addresses.splice(index, 1)
        this.addressMessage = 'Removed address successfully.'
        this.addressType = 'success'
        const self = this
        setTimeout(function () {
          self.addressMessage = ''
        }, 3000)
      } catch {
        this.addressMessage = 'Addresses tied to orders cannot be deleted.'
        this.addressType = 'error'
        const self = this
        setTimeout(function () {
          self.addressMessage = ''
        }, 3000)
      }
    },

    async update (name, event) {
      // changes defaults for company so 'default_billing_address' turns into
      // 'default_billing_address_id' + the event which is just a number or null
      const updater = {
        [`${name}_id`]: event ?? null
      }
      this.$apollo.mutate({
        mutation: UPDATE_COMPANY,
        variables: { input: updater }
      })
        .then(() => {
          this.resultHandler({
            message: 'Changed defaults successfully',
            type: 'success'
          })
        })
        .catch(() => {
          this.resultHandler({
            message: 'Could not alter defaults',
            type: 'error'
          })
        })
    },

    resultHandler ({ message, type }) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: message,
        color: type,
        timeout: 4000
      })
    }
  }
}
</script>
