











































































































































import { computed, defineComponent, provide, reactive, ref, Ref, watch } from '@vue/composition-api'
import {
  ApolloClients, useMutation, UseMutationReturn,
  useQuery,
  UseQueryReturn,
  useResult,
  UseResultReturn
} from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { IVuetifyDatatableHeader, tAlertObject } from '@/models/VuetifyModels'
import {
  Create__Location_Location__Input,
  Location_Location,
  ManyQueryInput,
  Mutation,
  MutationCreate__Location_LocationArgs,
  MutationUpdate__Location_LocationArgs,
  QueryLocation_LocationsArgs, Update__Location_Location__Input
} from '@/models/generated/graphql/ErpBackend'
import { CREATE_LOCATION, DELETE_LOCATION, GET_LOCATIONS__GRID, UPDATE_LOCATION } from '@/api/graphql/Constants/Locations'
import tAlert from '@/components/notifications/tAlert.vue'
import SitesSelect from '@/components/autocompletes/SitesSelect.vue'
import partTypes from '@/components/autocompletes/partTypes.vue'
import store from '@/store/store'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default defineComponent({
  name: 'LocationsManagement',
  components: {
    't-alert': tAlert,
    'site-select': SitesSelect,
    'part-type-select': partTypes,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  setup () {
    provide(ApolloClients, {
      default: apolloClient
    })

    /* Gui Prep */
    const dialog = ref(false)
    const dialogAlert: tAlertObject = reactive({
      message: '',
      type: 'info'
    })

    // searching
    const grouping: Ref<string> = ref('')
    watch(grouping, (value) => {
      queryInput.filters![0]!.value = value
    })

    // headers for table
    const headers: IVuetifyDatatableHeader[] = [
      { text: 'Location', value: 'name' },
      { text: 'Part Type', value: 'part_type' },
      { text: 'Site', value: 'site.name' },
      { text: 'Actions', value: 'actions' }
    ]

    // query input for receiving back locations
    const queryInput: ManyQueryInput = reactive({
      filters: [{ key: 'name__istartswith', value: '' }]
    })
    const isEnabled : Ref<boolean> = ref(false)

    // query for locations
    const { result, loading, onError, refetch }: UseQueryReturn<Location_Location[], QueryLocation_LocationsArgs> = useQuery(
      GET_LOCATIONS__GRID,
      () => ({ input: queryInput }),
      () => ({
        enabled: isEnabled.value
      })
    )
    onError(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Could not get locations',
        color: 'error',
        timeout: 4000
      })
    })
    // refetching helper
    function Refetch () {
      if (!isEnabled.value) {
        isEnabled.value = true
      } else {
        refetch()
      }
      setTimeout(() => {
        isEnabled.value = false
      }, 1000)
    }
    const locations: UseResultReturn<Location_Location[]> = useResult(result, [])

    /* Create Location Stuff */
    const create: Create__Location_Location__Input = reactive({
      name: '',
      site_id: '',
      part_type: ''
    })
    function ResetDialog () {
      create.name = ''
      editLocationInput.id = ''
      create.site_id = ''
      editLocationInput.site_id = ''
      editLocationInput.part_type = ''
      dialog.value = false
      editDialog.value = false
      hasCreationSuccess.value = false
      hasEditingSuccess.value = false
      dialogAlert.message = ''
    }
    const hasValidCreationData = computed(() => {
      return create.name !== '' && create.site_id !== ''
    })
    const hasCreationSuccess = ref(false)

    // CREATE MUTATION
    const {
      mutate: CreateLocationMutation,
      loading: isCreatingLocation,
      onError: onCreationFailure,
      onDone: onCreationSuccess
    }: UseMutationReturn <Mutation, MutationCreate__Location_LocationArgs> = useMutation(CREATE_LOCATION)
    onCreationFailure((error) => {
      dialogAlert.message = error?.message ?? 'Could not create location'
      dialogAlert.type = 'error'
    })
    onCreationSuccess(() => {
      dialogAlert.message = 'Creation successful'
      dialogAlert.type = 'success'
      hasCreationSuccess.value = true
      Refetch()
    })

    /* Edit Location Stuff */

    const editDialog = ref(false)
    const hasEditingSuccess: Ref<boolean> = ref(false)
    const editLocationInput: Update__Location_Location__Input = reactive({
      part_type: '',
      site_id: '',
      id: ''
    })

    function prepEdit (item: Location_Location) {
      editLocationInput.part_type = item.part_type
      editLocationInput.site_id = item.site.id
      editLocationInput.id = item.id
      editDialog.value = true
    }

    const {
      mutate: EditLocationMutation,
      loading: isEditing,
      onError: onEditingFailure,
      onDone: onEditingSuccess
    }: UseMutationReturn<Mutation, MutationUpdate__Location_LocationArgs> = useMutation(UPDATE_LOCATION)
    onEditingFailure((error) => {
      dialogAlert.message = error?.message ?? 'Could not edit location'
      dialogAlert.type = 'error'
    })
    onEditingSuccess(() => {
      dialogAlert.message = 'Edit successful'
      dialogAlert.type = 'success'
      hasEditingSuccess.value = true
      Refetch()
    })

    /* Delete Location Stuff */

    const {
      mutate: DeleteLocationMutation,
      onError: onDeletionFailure,
      onDone: onDeletionSuccess
    }: UseMutationReturn<Mutation, { id: string }> = useMutation(
      DELETE_LOCATION
    )
    onDeletionFailure(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Could not delete location. You may not have permissions or may still have items tied to it.',
        color: 'error'
      })
    })
    onDeletionSuccess(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Deleted successfully',
        color: 'success'
      })
      Refetch()
    })

    function DeleteLocation (location: Location_Location) {
      const hasPermissionsToDeleteLocation = true
      if (hasPermissionsToDeleteLocation && location.id) {
        DeleteLocationMutation({ id: location.id })
      }
    }

    return {
      dialog,
      locations,
      create,
      headers,
      loading,
      DeleteLocation,
      grouping,
      Refetch,
      isEditing,
      editLocationInput,
      editDialog,
      prepEdit,
      EditLocationMutation,
      hasEditingSuccess,
      queryInput,
      alert,
      dialogAlert,
      hasValidCreationData,
      hasCreationSuccess,
      CreateLocationMutation,
      isCreatingLocation,
      ResetDialog
    }
  }
})
