












import { computed, ComputedRef, defineComponent, Ref } from '@vue/composition-api'
import { useQuery, UseQueryReturn, useResult } from '@vue/apollo-composable'
import { GET_SITES } from '@/api/graphql/Constants/Locations'
import { Location_Site } from '@/models/generated/graphql/ErpBackend'

export default defineComponent({
  name: 'SitesSelect',
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const localSiteId = computed({
      get (): number {
        return Number(props.siteId)
      },
      set (value: number | string): void {
        emit('updated', { value: `${value}` })
      }
    })

    const { result, loading, onResult }: UseQueryReturn<Location_Site[], any> = useQuery(
      GET_SITES
    )
    onResult((result) => {
      if ((result?.data?.length ?? []) === 1 && result?.data[0].id) localSiteId.value = result.data[0].id
    })
    const sites = useResult(result, [])

    return {
      localSiteId,
      sites,
      loading
    }
  }
})
