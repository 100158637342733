





































































































import { computed, defineComponent, provide, reactive, ref, Ref, watch } from '@vue/composition-api'
import tAlert from '@/components/notifications/tAlert.vue'
import companyAddresses from '@/components/autocompletes/companyAddresses.vue'
import { apolloClient } from '@/api/graphql/apollo'
import {
  ApolloClients, useMutation,
  UseMutationReturn,
  useQuery,
  UseQueryReturn
} from '@vue/apollo-composable'
import { IVuetifyDatatableHeader, tAlertObject } from '@/models/VuetifyModels'
import { CREATE_SITE, DELETE_SITE, GET_SITES__GRID, UPDATE_SITE } from '@/api/graphql/Constants/Locations'
import {
  Create__Location_Site__Input,
  Location_Site,
  Mutation,
  MutationCreate__Location_SiteArgs,
  MutationDelete__Location_SiteArgs,
  MutationUpdate__Location_SiteArgs,
  Query
} from '@/models/generated/graphql/ErpBackend'
import { GetAddressDetailsForAutofill } from '@/lib/gqlDataGetters'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
export default defineComponent({
  name: 'siteManagement',
  components: {
    't-alert': tAlert,
    'company-addresses': companyAddresses,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  setup () {
    provide(ApolloClients, {
      default: apolloClient
    })

    const alert: tAlertObject = reactive({
      message: '',
      type: 'info'
    })
    watch(alert, value => {
      if (value.message) {
        setTimeout(() => {
          alert.message = ''
          alert.type = 'info'
        }, 5000)
      }
    })
    const dialogAlert: tAlertObject = reactive({
      message: '',
      type: 'info'
    })
    const dialog = ref(false)
    const isEditing = ref(false)

    const hasCreationSuccess = computed(() => dialogAlert.type === 'success')
    const hasValidCreationData = computed(() => {
      return create.name !== '' && create.name.length < 11
    })
    const invalidCreationDataMessage = computed(() => {
      let fields = ''
      for (const key in create) {
        // @ts-ignore
        if (create[key] === '') fields += `${key.replace('_', ' ').replace('id', '')},`
      }
      fields = fields.substring(0, fields.length - 1)
      return `The following fields need valid data: ${fields}`
    })

    const { loading, onError, onResult }: UseQueryReturn<Query, undefined> = useQuery(
      GET_SITES__GRID
    )
    onError(() => {
      alert.type = 'error'
      alert.message = 'Could not retrieve sites! Are you offline?'
    })
    onResult(result => {
      // @ts-ignore
      sites.value = (result?.data?.sites as Location_Site[]) ?? []
    })
    const sites: Ref<Location_Site[]> = ref([])
    const headers: IVuetifyDatatableHeader[] = [
      { text: 'Name', value: 'name' },
      { text: 'Address', value: 'addressName' },
      { text: 'Is Main Site', value: 'main_site' },
      { text: 'Actions', value: 'actions' }
    ]

    const create: Create__Location_Site__Input = reactive({
      name: '',
      address_id: '',
      short_name: computed(() => create.name),
      delivery_notes: '',
      main_site: false
    })

    const {
      mutate: CreateSiteMutation,
      loading: isCreatingSite,
      onError: onCreationFailure,
      onDone: onCreationSuccess
    }: UseMutationReturn<Mutation, MutationCreate__Location_SiteArgs> = useMutation(
      CREATE_SITE
    )
    onCreationFailure(() => {
      dialogAlert.message = 'We could not create the site, a server error has occurred.'
      dialogAlert.type = 'error'
    })
    onCreationSuccess(result => {
      if (result?.data?.Create__Location_Site) {
        sites.value.push(result?.data.Create__Location_Site)
        dialogAlert.message = 'Successfully created site'
        dialogAlert.type = 'success'
      }
    })

    const {
      mutate: UpdateSiteMutation,
      loading: isUpdatingSite,
      onError: onUpdateFailure,
      onDone: onUpdateSuccess
    }: UseMutationReturn<Mutation, MutationUpdate__Location_SiteArgs> = useMutation(
      UPDATE_SITE
    )
    onUpdateFailure(() => {
      dialogAlert.message = 'Could not update site, a server error has occurred.'
      dialogAlert.type = 'error'
    })
    onUpdateSuccess(() => {
      dialogAlert.message = 'Successfully created site'
      dialogAlert.type = 'success'
    })

    const {
      mutate: DeleteSiteMutation,
      onError: onDeletionFailure,
      onDone: onDeletionSuccess
    }: UseMutationReturn<Mutation, MutationDelete__Location_SiteArgs> = useMutation(
      DELETE_SITE
    )
    onDeletionFailure(() => {
      alert.message = 'Could not delete site, a server error has occurred.'
      alert.type = 'error'
    })
    onDeletionSuccess((result) => {
      const id = result?.data?.Delete__Location_Site?.id
      if (id) {
        const index = sites.value.findIndex(s => s.id === id)
        if (index > -1) {
          sites.value.splice(index, 1)
        }
      }
      alert.message = 'Successfully deleted site'
      alert.type = 'success'
    })

    function ResetCreationDialog () {
      // @ts-ignore
      if (create.id) delete create.id
      create.name = ''
      create.address_id = ''
      create.delivery_notes = ''
      dialog.value = false
      isEditing.value = false
      dialogAlert.message = ''
      dialogAlert.type = 'info'
    }

    function CreateOrUpdateSite (input: MutationCreate__Location_SiteArgs | MutationUpdate__Location_SiteArgs) {
      if (hasValidCreationData.value) {
        if (!isEditing.value) CreateSiteMutation(input as MutationCreate__Location_SiteArgs)
        else UpdateSiteMutation(input as MutationUpdate__Location_SiteArgs)
      } else {
        dialogAlert.message = invalidCreationDataMessage.value
        dialogAlert.type = 'warning'
      }
    }

    function EditSite (site: Location_Site) {
      isEditing.value = true
      // @ts-ignore
      create.id = site.id
      create.name = site.name
      create.address_id = `${site.address?.id}` ?? ''
      create.delivery_notes = site.delivery_notes
      dialog.value = true
    }
    function DeleteSite (site: Location_Site) {
      DeleteSiteMutation({ id: site.id })
    }

    return {
      alert,
      dialogAlert,
      dialog,
      sites,
      headers,
      loading,
      GetAddressDetailsForAutofill,
      create,
      CreateOrUpdateSite,
      isCreatingSite,
      isUpdatingSite,
      hasCreationSuccess,
      hasValidCreationData,
      ResetCreationDialog,
      isEditing,
      EditSite,
      DeleteSite
    }
  }
})
