<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :items="terms"
        :headers="headers"
        :loading="$apollo.queries.terms.loading"
      >
        <template #items="{ item }">
          <td>{{ item.name}}</td>
          <td>{{ item.days }}</td>
          <td>{{ item.description }}</td>
          <td>
            <v-icon
              @click="removeTerms(item.id)"
              color="error"
            >fal fa-delete</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <t-alert
        :message="deleteMessage"
        :type="deleteType"
      />
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        lazy
        max-width="700px"
      >
        <template #activator="{ on }">
          <v-btn
            color="info"
            v-on="on"
            data-cy="add-terms-button"
          >Add Terms</v-btn>
        </template>
        <v-card>
          <v-card-title class="purple">
            <h3 class="white--text">Add New Payment Terms</h3>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-xl>
              <v-layout row justify-center align-center>
                <v-flex>
                  <v-text-field
                    v-model="name"
                    label="Name"
                    box
                    data-cy="terms-name-field"
                  />
                </v-flex>
                <v-flex>
                  <v-text-field
                    v-model="description"
                    label="Description"
                    placeholder="Optional"
                    box
                    data-cy="terms-description-field"
                  />
                </v-flex>
                <v-flex>
                  <v-text-field
                    v-model.number="days"
                    label="Days"
                    mask="NNNNNNN"
                    box
                    data-cy="terms-days-field"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <t-alert
              :message="createMessage"
              :type="createType"
            />
            <v-spacer></v-spacer>
            <cancel-button :success="succeeded" @click="closeDialog"/>
            <submit-button :disabled="succeeded" :loading="saving" @click="submit"/>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { CREATE_TERM, DELETE_TERM, TERMS } from '@/api/graphql/Constants/TERMS'
import tAlert from '@/components/notifications/tAlert'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'termsSettings',
  components: {
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  data () {
    return {
      createType: 'info',
      createMessage: '',
      deleteType: 'info',
      deleteMessage: '',

      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Days', value: 'days' },
        { text: 'Description', value: 'description' }
      ],
      terms: [],

      // new term dialog
      dialog: false,
      name: '',
      description: '',
      days: 0,
      succeeded: false,
      saving: false
    }
  },
  apollo: {
    terms: {
      query: TERMS
    }
  },
  methods: {
    submit () {
      this.saving = true
      this.$apollo.mutate({
        mutation: CREATE_TERM,
        variables: {
          name: this.name,
          description: this.description,
          days: this.days
        }
      })
        .then(({ data: { term } }) => {
          this.terms.push(term)
          this.createResultHandler({ message: 'Successfully created payment terms', type: 'success' })
        })
        .catch((error) => {
          this.createResultHandler({ message: error, type: 'error' })
        })
    },

    removeTerms (id) {
      const index = this.terms.findIndex(t => t.id === id)
      if (index > -1) {
        this.$apollo.mutate({
          mutation: DELETE_TERM,
          variables: { id: id }
        })
          .then(() => {
            this.terms.splice(index, 1)
            this.deleteResultHandler({ message: 'Successfully deleted terms', type: 'success' })
          })
          .catch(error => {
            this.deleteResultHandler({ message: error, type: 'error' })
          })
      }
    },

    closeDialog () {
      this.dialog = false
      this.createMessage = ''
      this.createType = 'info'
      this.name = ''
      this.description = ''
      this.days = 0
      this.succeeded = false
    },

    createResultHandler ({ message, type }) {
      this.createMessage = message
      this.createType = type
      if (type === 'success') {
        this.succeeded = true
      }
      this.saving = false
    },

    deleteResultHandler ({ message, type }) {
      this.deleteMessage = message
      this.deleteType = type
    }

  }
}
</script>
